import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Textarea,
  HStack,
  Image,
  Input,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import AddressIndex from '../../Components/Address/AddressIndex';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { formatFrice } from '../../Utils/numberUtil';

function OrdersPage() {

  const toast = useToast();
  const param = useLocation();
  const [data, setData] = useState(param?.state || {});
  const [itemData, setItemData] = useState(data?.items || []);
  const [priceProduct, setPriceProduct] = useState(0);
  const [priceTax, setPriceTax] = useState(0);
  const [priceInsurance, setPriceInsurance] = useState(0);
  const [priceShipment, setPriceShipment] = useState(0);
  const [loading, setLoading] = useState(false);
  const [linkRedirect, setLinkRedirect] = useState('');
  const [insuranceCheckboxes, setInsuranceCheckboxes] = useState([]);


  const navigate = useNavigate();

  if (param?.state === undefined || param?.state === null || param?.state?.item?.length < 1) {
    navigate(-1)
  }

  useEffect(() => {
    handlePrice();

  }, [itemData]);

  const handleShipment = async (productId) => {
    try {
      const res = await axiosInstanceBarier.get(`/shipping-cost?product_id=${productId}`);
      return res.data;
    } catch (error) {
      console.log(error, 'ini error');
      return null;
    }
  };

  const updateShippingCosts = async () => {
    const updatedItems = [];

    for (let i = 0; i < data?.items?.length; i++) {
      const item = data?.items[i];
      const productId = item?.variant?._id;
      const shippingInfo = await handleShipment(productId);

      if (shippingInfo === undefined) {
        return toast({
          title: 'DapetKomisi',
          description: 'we dont have track your address, please input your address',
          status: 'warning',
          isClosable: true,
          position: 'top'

        });
      }
      if (shippingInfo) {
        const foundItem = data?.items?.find((dataItem) => dataItem.variant._id === productId);

        if (foundItem) {
          const shippingOptions = shippingInfo.map((provider) => {
            const services = provider.costs.map((service) => ({
              name: service?.service || "",
              cost: service?.cost[0]?.value || 0,
              etd: service?.cost[0]?.etd || "",
              description: service?.description || "",
              provider: provider?.code || "",
              note: service?.cost[0]?.note || "",
            }));
            return {
              name: provider.name,
              services: services,
            };
          });

          const updatedItem = {
            ...foundItem,
            shippingOptions: shippingOptions,
          };
          updatedItems.push(updatedItem);

         
        }
      }
    }

    setData({ ...data, items: updatedItems });
  };

  useEffect(() => {
    updateShippingCosts();
  }, []);

  const handlePrice = () => {
    let totalPrice = 0;
    let totalShipmentPrice = 0;
    let totalInsurancePrice = 0;

    data?.items?.forEach((item, index) => {
      const { variant, quantity } = item;
      const { price } = variant;

      totalPrice += quantity * price;

      if (itemData[index]?.insurance) {
        totalInsurancePrice += 5000
      }

      if (itemData[index]?.selectedShipment > 0) {
        const selectedShippingOption = item.shippingOptions.find(
          option => option.services.some(service => service.cost === itemData[index].selectedShipment)
        );

        if (selectedShippingOption) {
          const selectedService = selectedShippingOption.services.find(
            service => service.cost === itemData[index].selectedShipment
          );

          if (selectedService) {
            totalShipmentPrice += selectedService.cost;
          }
        }
      }
    });

    setPriceProduct(totalPrice);
    setPriceInsurance(totalInsurancePrice);
    setPriceShipment(totalShipmentPrice);
  };

  useEffect(() => {
    handlePrice();

    const initialItemData = data?.items?.map(item => ({
      description: "terimakasih sudah menyediakan barang sebagus ini :)",
      selectedShipment: 0,
      shippingOptions: item?.shippingOptions || [],
    }));
    setItemData(initialItemData);

  }, []);


  const handlePay = async () => {
    let updateData = { items: [] };

    data?.items?.forEach((item, index) => {
      const selectedShipmentCost = item.shippingOptions.find(
        option => option.services.some(service => service.cost === itemData[index].selectedShipment)
      );

      if (selectedShipmentCost) {
        const selectedService = selectedShipmentCost.services.find(
          service => service.cost === itemData[index].selectedShipment
        );

        if (selectedService) {
          const shippingProvider = {
            provider: selectedService?.provider || "",
            service: selectedService?.name || "",
            description: selectedService?.description || "",
            value: selectedService?.cost || 0,
            etd: selectedService?.etd || "",
            note: selectedService?.note || ""
          };

          updateData.items.push({
            variant_id: item?.variant?._id,
            product: item?.product, 
            quantity: item?.quantity,
            description: itemData[index]?.description,
            shipping_provider: shippingProvider
          });
        }
      }
    });

    setLoading(true);
    try {
      const res = await axiosInstanceBarier.post('/order/create', updateData);
      if (res.order) {
        setLinkRedirect(res?.order?.url);
        window.open(res?.order?.url, '_blank');
        toast({
          title: 'DapetKomisi',
          description: 'Success create order.',
          status: 'success',
          isClosable: true,
          position: 'top'

        });
        navigate("/orders-list")
      }
    } catch (error) {
      console.log(error, 'ini error')
      toast({
        title: 'DapetKomisi',
        description: error?.response?.data?.message,
        status: 'error',
        isClosable: true,
        position: 'top'

      });
    } finally {
      setLoading(false);
    }
  };

  const handleDescriptionChange = (index, description) => {
    const updatedItemData = [...itemData];
    updatedItemData[index].description = description;
    setItemData(updatedItemData);
  };

  const handleShipmentSelect = (index, selectedShipment) => {
    const updatedItemData = [...itemData];
    updatedItemData[index].selectedShipment = Number(selectedShipment);
    setItemData(updatedItemData);
  };

  return (
    <Stack p={[1, 1, 5]} spacing={10}>
      <Stack shadow="md" borderRadius="md" spacing={3} pt={5}>
        <AddressIndex />
      </Stack>

      <Stack spacing={5}>
        <Text fontWeight="bold">Produk List</Text>
        <Stack>
          {data?.items?.length > 0 &&
            data?.items?.map((item, index) => (
              <HStack
                spacing={5}
                bgColor="white"
                shadow="md"
                p={2}
                borderRadius="md"
                key={index}
              >
                <Image
                  src={item?.variant?.images || "https://picsum.photos/300/200"}
                  w="200px"
                  h="100px"
                  objectFit="cover"
                  borderRadius="md"
                />
                <Stack w="full" spacing={5} flexDirection={["column", "row", "row"]}>
                  <Stack>
                    <Text fontWeight={500} fontSize="sm" textTransform="capitalize">
                      {item?.variant?.title}
                    </Text>
                    <Text fontSize="sm" color="gray.700">
                      Jumlah: {item?.quantity || 0}
                    </Text>
                    <Text fontWeight={500}>
                      Rp. {formatFrice(parseFloat(item?.variant?.price || 0))} / Pcs
                    </Text>
                    <Checkbox
                      isChecked={insuranceCheckboxes[index] || false}
                      onChange={() => {
                        const updatedCheckboxes = [...insuranceCheckboxes];
                        updatedCheckboxes[index] = !insuranceCheckboxes[index];
                        setInsuranceCheckboxes(updatedCheckboxes);

                        const updatedItemData = [...itemData];
                        updatedItemData[index].insurance = updatedCheckboxes[index];
                        setItemData(updatedItemData);
                      }}
                      size="sm"
                      color="red.600"
                      fontStyle="italic"
                    >
                       Pilih asuransi untuk melindungi produk kamu
                    </Checkbox>
                  </Stack>

                  <Stack>
                    {item?.shippingOptions && (
                      <Select
                        borderRadius="md"
                        size="sm"
                        placeholder="Pilih pengiriman..."
                        value={itemData[index].selectedShipment || ''}
                        onChange={(e) => handleShipmentSelect(index, e.target.value)}
                      >
                        {item?.shippingOptions && (
                          <>
                            {item.shippingOptions.map((provider, providerIndex) => (
                              <optgroup key={providerIndex} label={provider.name}>
                                {provider.services.map((service, serviceIndex) => (
                                  <option key={serviceIndex} value={service.cost}>
                                    {service.name} - Rp. {service.cost} - {service.etd}
                                  </option>
                                ))}
                              </optgroup>
                            ))}
                          </>
                        )}
                      </Select>
                    )}

                    <Textarea
                      borderRadius="md"
                      size="sm"
                      placeholder="Catatan untuk penjual ..."
                      onChange={(e) => handleDescriptionChange(index, e.target.value)}
                    />
                  </Stack>
                </Stack>
              </HStack>
            ))}
        </Stack>
      </Stack>

      <Stack spacing={5}>
        <Text fontWeight="bold">Detail Pesanan</Text>
        <SimpleGrid columns={[1, 1, 1]} gap={5}>
          <Stack p={5} bgColor="red.600" color="white" borderRadius="md">
            <Stack>
              <Text fontWeight="bold">Detail harga</Text>
              <Divider />
              <Spacer />
              <Stack fontSize="sm">
                <HStack>
                  <Text>Produk</Text>
                  <Spacer />
                  <Text>Rp. {formatFrice(priceProduct || 0)}</Text>
                </HStack>
                <HStack>
                  <Text>PPn</Text>
                  <Spacer />
                  <Text>Rp. {formatFrice(priceTax || 0)}</Text>
                </HStack>
                <HStack>
                  <Text>Asuransi</Text>
                  <Spacer />
                  <Text>Rp. {formatFrice(priceInsurance || 0)}</Text>
                </HStack>
                <HStack>
                  <Text>Pengiriman</Text>
                  <Spacer />
                  <Text>Rp. {formatFrice(priceShipment || 0)}</Text>
                </HStack>
                <Divider />
                <HStack>
                  <Text fontWeight={500}>Total</Text>
                  <Spacer />
                  <Text fontWeight={500}>
                    Rp. {formatFrice(priceProduct + priceTax + priceInsurance + priceShipment)}
                  </Text>
                </HStack>
              </Stack>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Stack>

      <Stack spacing={5} bgColor="blackAlpha.900" p={5} color="gray.200" borderRadius="md">
        <Text fontWeight="bold">Term & Conditions</Text>
        <HStack alignItems="flex-start" spacing={5}>
          <Checkbox bgColor="white" />
          <Stack>
            <Text fontSize="sm">
              Dengan melakukan pembayaran atas produk yang Anda pilih, Anda dianggap telah memahami dan menyetujui sepenuhnya syarat dan ketentuan yang berlaku di DapatKomisi.com. Syarat dan ketentuan ini merangkum berbagai aspek yang penting dalam transaksi, termasuk ketersediaan produk, harga yang berlaku, proses pembayaran, kebijakan pengiriman yang bisa bervariasi, serta tata cara pengembalian barang dan prosedur klaim. Kami ingin menekankan pentingnya untuk membaca dengan teliti setiap informasi terkait produk, kebijakan pengiriman, serta syarat dan ketentuan lain yang berlaku sebelum menyelesaikan pembayaran. Dalam kasus terjadi perubahan atau penyesuaian terhadap syarat dan ketentuan kami, kami akan memberitahukan Anda mengenai perubahan tersebut agar Anda tetap memahami dan menyetujui kondisi terbaru sebelum melanjutkan transaksi pembayaran.
            </Text>
            <Text fontSize="sm">
              Kami mengajak Anda untuk memahami bahwa setiap transaksi yang Anda lakukan di DapatKomisi.com tunduk pada syarat dan ketentuan yang telah ditetapkan. Perlu kami sampaikan bahwa kami berkomitmen untuk memberikan layanan yang terbaik bagi setiap pelanggan kami. Namun, sebagai langkah antisipasi, kami merekomendasikan untuk selalu mengikuti dan memahami informasi terbaru terkait produk, prosedur pembayaran, kebijakan pengiriman, serta syarat dan ketentuan lainnya yang berlaku. Dengan memilih dan membayar produk, Anda turut menunjukkan bahwa Anda telah membaca, memahami, dan menyetujui sepenuhnya setiap ketentuan yang kami tetapkan untuk transaksi di DapatKomisi.com. Segala perubahan atau pembaruan yang terjadi akan kami sampaikan agar Anda tetap dapat melakukan transaksi secara terinformasi dan terpercaya.
            </Text>
          </Stack>
        </HStack>
      </Stack>

      <Stack>
        {linkRedirect !== '' && (
          <HStack>
            <Text color="red" fontSize="sm">
              If you cannot redirect to payment, please{' '}
            </Text>
            <Text
              color="red"
              fontSize="sm"
              fontStyle="italic"
              fontWeight="bold"
              onClick={() => window.open(linkRedirect, '_blank')}
              cursor="pointer"
            >
              Click here.
            </Text>
          </HStack>
        )}
      </Stack>

      <Stack alignItems="flex-end" pb={5}>
        <Button isLoading={loading} colorScheme="red" onClick={() => handlePay()}>
          <Text fontSize="sm">Bayar sekarang</Text>
        </Button>
      </Stack>
    </Stack>
  );
}

export default OrdersPage;
